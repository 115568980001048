import React, { useEffect, useState } from "react";
import { IPageLinkProps } from "../../page.props";
import { TabLayout } from "../../foundation/tab_layout";
import {faAward, faFileExport, faList, faPerson} from "@fortawesome/free-solid-svg-icons";
import { MemberDetailTraining } from "./member_detail_training";
import { MemberDetailRoles } from "./member_detail_roles";
import { MemberDetailGeneral } from "./member_detail_general";
import { MemberService } from "../../../api/member";
import { useParamId, useParamRole } from "../../useParam";
import { IMember, IAdminMemberExtended } from "../../../api/models/MemberModel";
import {BackButton} from "../../form";
import {ItemNav} from "../../form/ItemNav";
import styles from "./member.module.scss";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {toaster} from "../../../core/toaster";
import Papa from 'papaparse';

const MemberDetail: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const role = useParamRole(user);
    const id = useParamId();
    const [member, setMember] = useState<IMember | null>(null);
    const [isExporting, setIsExporting] = useState<boolean>(false);

    useEffect(() => {
        if (id !== null) {
            if (id != 0) {
                MemberService.get(role.orgId, id).then((m) => {
                    setMember(m);
                });
            } else {
                setMember({ id: 0 } as IMember);  // Set member with id 0 for new member
            }
        }
        // Cleanup function to reset state when component unmounts
        return () => {
            //setMember(null);
        };
    }, [id, role, refresh]);

    const handleExportCSV = async (): Promise<void> => {
        if (isExporting) return;

        return toaster(async () => {
            setIsExporting(true);
            try {
                // Fetch all members
                const membersCsv: IAdminMemberExtended[] = await MemberService.allcsv(role.orgId);

                const data = membersCsv.map(p => ({
                    'ID': p.id,
                    'Nachname': p.surname,
                    'Vorname': p.firstname,
                    'Zweitname': p.middlename,
                    'Titel': p.title,
                    'Geburtsdatum': p.birthdate,
                    'Straße': p.street,
                    'Hausnummer': p.houseNo,
                    'Stiege': p.stair,
                    'Türnummer': p.doorNo,
                    'PLZ': p.zip,
                    'Ort': p.city,
                    'Land': p.country,
                    'Geschlecht': formatGender(p.gender),
                    'Telefon Mobil': p.mobile,
                    'Email': p.email,
                    'Gesperrt': p.gesperrt,
                    'Gewichtsklasse': p.weightClass,
                    'Fax': p.fax,
                    'Nationalität': p.nationality,
                    'UUID': p.uuid,
                    'Org ID': p.orgId,
                    'Aktuelle Lizenz': p.currentLicense,
                    'Mitgliedschaft Ende': p.membershipEnd,
                    'Nationalität überprüft': p.nationchecked,
                    'Attest': p.attest,
                    'Verstorben': p.verstorben,
                    'Zusatz 1': p.add_1,
                    'Zusatz 2': p.add_2,
                    'Zusatz 3': p.add_3,
                    'Zusatz 4': p.add_4,
                    'Zusatz 5': p.add_5,
                    'Email ignorieren': p.emailIgnore,
                    'Email2': p.email2,
                    'Telefon 4': p.tel_4,
                    'Telefon 5': p.tel_5,
                    'Telefon Festnetz': p.tel_festnetz,
                    'Telefon Firma': p.tel_firma,
                    'Titel2': p.title2,
                    'Bild UUID': p.imageUuid,
                    'Höchster Grad': p.highestGrade,
                    'Graduierungsdatum': p.gradeDate,
                    'Judo Register': p.judoRegister,
                    'Vereinsname': p.orgName,
                }));

                // Configure CSV options
                const csvOptions = {
                    quotes: true,      // Quote all fields
                    delimiter: ';',    // Use semicolon for German Excel compatibility
                    header: true,
                };

                // Generate CSV content with BOM for proper UTF-8 encoding in Excel
                const csvContent = '\ufeff' + Papa.unparse(data, csvOptions);

                // Create and configure the blob
                const blob = new Blob([csvContent], {
                    type: 'text/csv;charset=utf-8;'
                });

                // Generate filename with current date
                const filename = `Mitglieder_${role.orgId}_${new Date().toISOString().split('T')[0]}.csv`;

                // For other browsers
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.display = 'none';

                document.body.appendChild(link);
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

                return undefined; // Changed from null to undefined
            } finally {
                setIsExporting(false);
            }
        }, {
            success: "Export erfolgreich abgeschlossen",
            failure: "Export fehlgeschlagen"
        }) as Promise<void>; // Add type assertion here
    };

// Helper function to format gender
    const formatGender = (gender: 'm' | 'w' | 'd' | 'x'): string => {
        const genderMap: Record<string, string> = {
            'm': 'männlich',
            'w': 'weiblich',
            'd': 'divers',
            'x': 'keine Angabe'
        };
        return genderMap[gender] || gender;
    };

    console.log('member?.id', member?.id);

    return (
        <>
            {(member?.id != undefined && member?.id != 0) && (<BackButton label={'Übersicht & Datenexport'} navigateTo={gotoUrl}/>)}
            <TabLayout
                items={[
                    {
                        label: 'Stamm\u00ADdaten',
                        icon: faPerson,
                        component: <MemberDetailGeneral gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />,
                    },
                    ...(member?.id !== undefined && member.id != 0
                        ? [
                            {
                                label: 'Aus\u00ADbil\u00ADdungen',
                                icon: faAward,
                                component: <MemberDetailTraining gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />,
                            },
                            {
                                label: 'Funk\u00ADtionen & Lizenzen',
                                icon: faList,
                                component: <MemberDetailRoles gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />,
                            },
                        ]
                        : []),
                ]}
            />
            {member?.id == undefined || member?.id == 0 ? (
            <div className={styles.bodyControls}>

                <ItemNavGroup label="Exporte" className={styles.itemnavgroup}>
                    <ItemNav
                        className={styles.button}
                        primary={true}
                        label={`CSV-Mitgliederexport`}
                        icon={faFileExport}
                        onClick={handleExportCSV}

                    />
                </ItemNavGroup>
            </div>):''}
        </>
    );
};

export default MemberDetail;