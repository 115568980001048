import React, {useEffect, useMemo, useState} from 'react';
import image from "../../theme/images/profileImage.png";
import classNames from "classnames";
import styles from './AvatarImage.module.scss';

interface IAvatarImageProps {
    src?: string;
    alt?: string;
    onError?: () => void;
    className?: string;
}

const AvatarImage: React.FC<IAvatarImageProps> = ({src, alt, onError, className}) => {
    const [active, setActive] = useState(false);
    const [failed, setFailed] = useState(false);
    const [retryCount, setRetryCount] = useState(0);

    const imgSrc = useMemo(() => {
        if (!src) return undefined;
        return new URL(src, window.location.origin).toString();
    }, [src]);

    useEffect(() => {
        // Reset states when src changes
        setFailed(false);
        setActive(false);
        setRetryCount(0);
    }, [imgSrc]);

    const onErrorDefault = () => {
        if (retryCount < 3) {
            setRetryCount(prev => prev + 1);
            setFailed(false);
        } else {
            setFailed(true);
            onError && onError();
        }
    };

    const onLoadSuccess = () => {
        setActive(true);
    };

    // Preload image to ensure it's in memory
    useEffect(() => {
        if (src) {
            const img = new Image();
            img.src = src;
            img.onload = onLoadSuccess;
            img.onerror = onErrorDefault;
        }
    }, [src]);

    return (
        <>
            {(!failed && src) ? (
                <img
                    className={classNames(className, styles.image, active && styles.active)}
                    src={src}
                    loading="eager"
                    alt={alt || 'Profile Image'}
                    onError={onErrorDefault}
                    onLoad={onLoadSuccess}
                    key={`${src}-${retryCount}`}
                    decoding="async"
                />
            ) : (
                <img
                    className={classNames(className, styles.image, active && styles.active)}
                    src={image}
                    loading="eager"
                    alt={alt || 'Default Profile Image'}
                    onLoad={onLoadSuccess}
                />
            )}
        </>
    );
};

export default AvatarImage;