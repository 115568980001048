export interface IMeetingModelParamAkGk {
    AK?: string;
    U18?: string;
    U16?: string;
    U21?: string;
    U10?: string;
    U12?: string;
    U14?: string;
    U23?: string;
    U8?: string;
}

export const weightClassSort: string[] = [
    "U20",
    "U22",
    "U24",
    "U25",
    "U27",
    "U28",
    "U30",
    "U32",
    "U34",
    "U36",
    "U38",
    "U40",
    "U42",
    "U44",
    "P44",
    "U46",
    "P46",
    "U48",
    "P48",
    "U50",
    "U52",
    "P52",
    "U55",
    "P55",
    "U57",
    "P57",
    "U60",
    "U63",
    "U66",
    "P66",
    "U70",
    "P70",
    "U73",
    "U78",
    "P78",
    "U81",
    "P81",
    "U90",
    "P90",
    "U100",
    "P100",
];

export interface IMeetingModelParamMap {
    z?: number;
    lat: any;
    lon: any;
}

export interface IFileUpload {
    mime: string;
    name: string;
    original: string;
}

export interface IMeetingModelParam {
    MAP?: IMeetingModelParamMap;
    ORT?: string;
    URL?: string;
    AKGK?: {[key:string]:boolean};
    FILE?: any;
    TEXT?: string;
    STAFF?: any;
    PRICING?: string;
    OPENFROM?: string;
    SHOW_AKGK?: string;
    TEILNAHME?: string;
    BUNDESWEIT?: any;
    TEILNEHMER?: any;
    COUNTRY_IOC?: string;
    NENNSCHLUSS?: string;
    PUBLISH_API?: any;
    MEETINGROLES?: string;
    NATIONENCHECK?: any;
    kyu_pruefung_id?: number;
    MEETINGROLES_REQ?: number[];
    STREAMING_URL?: string;
}

export interface IMeeting {
    id: number;
    name: string;
    meetingtype_id: number;
    startdate: string;
    enddate: string;
    param?: IMeetingModelParam;
    org_id: number;
    org_mutter_id?: number;
    meetingakgk_id?: number;
    ages_nennbar: any;
    created_at: string;
    updated_at: string;
    mutter_id?: number;
    bl_begeg_id: any;
    judoregister_id?: number;
    open_from?: string;
    open_to?: string;
    //meetingstatus_id: any;
    //user_id?: number;
    comment: any;
    fileUploads?: IFileUpload[];
    meetingtype_param?: IMeetingTypeParam;
    orgMutterNameKurz?: string;
}

interface IMeetingTypeParam {
    TYPE: string;
    SUBTYPE?: string;
    TEILNAHME?: string | null;
    PUBLISH_API: number;
    SHOW_AKGK?: number;
    BUNDESWEIT?: number;
    NATIONENCHECK?: number;
    MEETINGROLES?: number[];
}


export interface IMeetingCandidateWeightClass {
    weightClass: {[key: string]: string[]}[]
}

export interface IMeetingCandidateParams {
    meeting_mg_id?: number;
    AK?: string;
    GK?: string;
    invitationTimeoutDate?: string;
}
export interface IMeetingCandidate {
    allowed: IMeetingCandidateWeightClass;
    mgId: number;
    uuid?: string;
    surname: string;
    firstname: string;
    birthdate: string;
    trainingBelt: string;
    trainingGrade: string;
    trainingSort: number;
    lastKyuDate: string;
    judocard?: number;
    beltsvg: string;
    role?:string;
    // assignedMgId?: number;
    nextTrainingId?: number;
    nextName?: string;
    deprecatedAlreadyAssigned?: number;
    nextBeltSvg?: string;
    outError?: {
        errcode: string;
        message: string;
    }[]
    status?: string;
    params: IMeetingCandidateParams[]|IMeetingCandidateParams;
    orgName: string;
    gender: string;
    judomanagerId?:string;
}

export interface IMeetingCandidatesAll {
    jamaId: number;                    // `json:"jamaId" db:"mg_id"`
    judomanagerId: string | null;      // `json:"judoManagerId" db:"judomanager_id"`
    familyName: string;                // `json:"familyName" db:"fname"`
    firstName: string;                 // `json:"firstName" db:"vname1"`
    born: string;                      // `json:"born" db:"born"`
    ageEndOfYear: number;             // `json:"ageEndOfYear" db:"age_endofyear"`
    gender: 'm' | 'w' | 'd' | 'x';    // `json:"gender" db:"geschlecht"`
    ioc: string | null;               // `json:"ioc" db:"IOC"`
    licenseB: string | null;          // `json:"licenseB" db:"license_b"`
    nationChecked: string | null;     // `json:"nationChecked" db:"nationchecked"`
    medical: string | null;           // `json:"medical" db:"med"`
    ausbDesc: string | null;          // `json:"ausbDesc" db:"ausb_desc"`
    ausbName: string | null;          // `json:"ausbName" db:"ausb_name"`
    AK: string | null;                // `json:"AK" db:"AK"`
    GK: string | null;                // `json:"GK" db:"GK"`
    invitationTimeoutDate: string | null;  // `json:"invitationTimeoutDate" db:"invitationTimeoutDate"`
    role: string;                     // `json:"role" db:"rolle"`
    orgId: number;                    // `json:"orgId" db:"org_id"`
    orgName: string;                  // `json:"orgName" db:"name_kurz"`
    LV: number | null;                // `json:"LV" db:"mutter"`
    status: 'eingeladen' | 'akzeptiert' | 'abgelehnt' | 'anwesend' | 'potentieller Teilnehmer';  // `json:"status" db:"status"`

    // If you want to keep the existing Nationality field
    Nationality?: string;             // Keep if still needed
}
