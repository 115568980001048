import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IMember} from "../../../api/models/MemberModel";
import {MemberService} from "../../../api/member";
import {useParamId, useParamRole} from "../../useParam";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {ItemNav} from "../../form/ItemNav";
import {FD, Input, Item, Segment} from "../../form";
import AvatarImage from "../../../components/foundation/AvatarImage";
import styles from './member.module.scss'
import {useNavigate} from "react-router-dom";
import {Loading} from "../../foundation/Loading";
import {faPerson} from "@fortawesome/free-solid-svg-icons";
import {IPageLinkProps} from "../../page.props";
import classNames from "classnames";
import {Barcode} from "../../../services";

const BARCODE_TYPES = {
    NO_CODE: 1,
    JAMA: 2,
    JUDO_MANAGER: 3
} as const;

export const Member: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh}) => {
    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState<IMember[]>([]);
    const [search, setSearch] = useState<string>('');

    const navigate = useNavigate();
    const role = useParamRole(user);
    const id = useParamId();
    const [imageVersions, setImageVersions] = useState<{[key: string]: number}>({});
    const [useMgId, setUseMgId] = useState<number>(1);

    const filterLength = 1500;

    useEffect(() => {
        const trimmedSearch = search.trim();
        const debounceTimeout = setTimeout(() => {
            setLoading(true)
            if (user) {
                if (role.orgLevel == 3) {  // Added check for orgLevel==3
                    // When search is empty, fetch all members
                    MemberService.all(role.orgId)
                        .then((m) => {
                            setMembers(m ? (Array.isArray(m) ? m : [m]) : []);
                        })
                        .catch((error) => {
                            console.error("Error fetching members:", error);
                            setMembers([]);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    // First load all members of the organization
                    MemberService.all(role.orgId, false)
                        .then((m) => {
                            setMembers(m ? (Array.isArray(m) ? m : [m]) : []);
                        })
                        .catch((error) => {
                            console.error("Error fetching members:", error);
                            setMembers([]);
                        })
                        .finally(() => {
                            setLoading(false);
                        });

                    // When there's a search term (>2 chars), use search functionality
                    if (trimmedSearch.length > 2 || (Number(trimmedSearch) >= 1 && Number(trimmedSearch) <= 999999)) {
                        console.log("search:", trimmedSearch);
                        MemberService.searchMembersAllByName(role.orgId, trimmedSearch)
                            .then((m) => {
                                setMembers(m ? (Array.isArray(m) ? m : [m]) : []);
                            })
                            .catch((error) => {
                                console.error("Error fetching members:", error);
                                setMembers([]);
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    } else {
                        // If search string is too short, clear results
                        setMembers([]);
                        setLoading(false);
                    }
                }
            } else {
                setLoading(false);
                setMembers([]);
            }
        }, 300); // 300ms debounce delay

        return () => clearTimeout(debounceTimeout);
    }, [user, refresh, search, role.orgId, role.orgLevel]);

    const goto = (id: number) => {
        // /more/organization/member
        navigate(`${gotoUrl}/${id}`)
    }

    const filter = useMemo(() => {
        // If search is empty, just filter by organization
        if (search.length === 0) {
            return members.filter(x => x.orgId === role.orgId);
        }

        // If search length is 2 or less, return empty array
        if (search.length <= 2) {
            return [];
        }

        const searchLower = search.toLowerCase().trim();

        return members.filter(x => {
            // Check if search is a valid integer (ID)
            if (!isNaN(Number(search)) && x.id !== undefined && x.id.toString().includes(search)) {
                return true;
            }

            const fullName = `${x.surname} ${x.firstname}`.toLowerCase();

            // Check if the search string is included in the full name
            return fullName.includes(searchLower);
        });
    }, [members, role.orgId, search]);

    // Add event listener for image updates
    useEffect(() => {
        console.log('Setting up image update listener');

        const handleImageUpdate = (event: CustomEvent) => {
            const uuid = event.detail?.uuid;
            if (uuid) {
                console.log('Updating version for uuid:', uuid);
                // Force a more dramatic change in version
                setImageVersions(prev => {
                    const newVersions = {
                        ...prev,
                        [uuid]: Date.now() + Math.random()
                    };
                    console.log('New image versions:', newVersions);
                    return newVersions;
                });
            }
        };

        window.addEventListener('profileImageUpdated', handleImageUpdate as EventListener);

        return () => {
            window.removeEventListener('profileImageUpdated', handleImageUpdate as EventListener);
        };
    }, []);

    const getImageUrl = useCallback((uuid: string) => {
        const version = imageVersions[uuid];
        const url = `${MemberService.imageUrl(uuid)}${version ? `?v=${version}` : ''}`;
        //console.log('Generated image URL:', url);
        return url;
    }, [imageVersions]);
    return <>
        <ItemNavGroup label={role?.orgName} className={styles.itemnavgroup}>
            <>
                {loading && <Loading/>}
                <ItemNav
                    align="center"
                    label="Mitglied hinzufügen"
                    icon={faPerson}
                    onClick={() => goto(0)}
                />
                <Item type="full" className={styles.search}>
                    <Input onChange={setSearch} value={search} className={styles.name} placeholder={"Suche nach Name oder Jama-ID"}/>
                </Item>
                {!loading && (filter?.length > 0 ? (
                    <>
                            <Item type="full" label="Barcode" size={12}>
                                <Segment
                                    value={useMgId}
                                    onChange={setUseMgId}
                                    options={[
                                        {label: "NoCode", value: BARCODE_TYPES.NO_CODE},
                                        {label: "JAMA", value: BARCODE_TYPES.JAMA},
                                        {label: "JudoMan.", value: BARCODE_TYPES.JUDO_MANAGER}
                                    ]}
                                />
                            </Item>

                        {filter.slice(0, filterLength).map((m) => (
                            <ItemNav
                                key={`member-${m.id}-${imageVersions[m.uuid] || 'no-version'}`}  // Add version to key
                                className={classNames(styles.navItem, !m.isActive && styles.itemInactive)}
                                onClick={() => goto(m.id || 0)}
                                selected={id === m.id}
                            >
                                <div className={styles.item}>
                                    <AvatarImage
                                        key={`avatar-${m.uuid}-${imageVersions[m.uuid] || 'no-version'}`}  // Add key to force remount
                                        src={getImageUrl(m.uuid)}
                                        className={styles.avatar}
                                    />
                                    <div className={styles.text}>
                                        <div className={styles.nameLicContainer}>
                                            <div className={styles.name}>{m.surname.toUpperCase()} {m.firstname.charAt(0).toUpperCase() + m.firstname.toLowerCase().slice(1)}</div>
                                            <div
                                                className={`${styles.license} ${m?.isActive ? m?.currentLicense ? styles.active : styles.expired : styles.inactive }`}>{m.id}
                                            </div>
                                        </div>
                                        <div className={styles.bottom}>
                                            <div
                                                className={styles.org}>{m.orgId && m.orgId !== role.orgId && profile.organizations.find(x => x.id === m.orgId)?.nameShort}</div>
                                            <div className={styles.birthdate}>{FD(m.birthdate)}</div>
                                        </div>
                                    </div>
                                </div>
                                { useMgId > 1 ?
                                    (<div
                                    className={styles.barcode}
                                    dangerouslySetInnerHTML={{
                                        __html: Barcode.generate(
                                            (() => {
                                                switch (useMgId) {
                                                    case BARCODE_TYPES.JAMA:
                                                        return `JAMA${m.id}`;
                                                    case BARCODE_TYPES.JUDO_MANAGER:
                                                        return `${m.judomanagerId}`;
                                                    case BARCODE_TYPES.NO_CODE:
                                                    default:
                                                        return '';
                                                }
                                            })(),
                                            '#000000',2,42,12
                                        )
                                    }}
                                />):''}
                            </ItemNav>
                        ))}
                        {/*{filter.length < members.length && filter.length > filterLength && search.length > 0 && (<Item>Es werden nur die ersten {filterLength} Ergebnise angezeigt</Item>)}*/}
                        <Item>Es werden nur die ersten {filterLength} Ergebnise angezeigt</Item>
                        <Item> </Item>
                    </>) : <Item type="list" label="Keine Mitglieder gefunden"/>)}
            </>
        </ItemNavGroup>
    </>
}
